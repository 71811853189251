import http from './http-common'
import authHeader from './auth-header'

const getAll = ({ attributes, include } = {}) => {
  return http.get('/enumerations', { headers: authHeader() })
}

const get = (id) => {
  return http.get(`/enumerations/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.post('/enumerations', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.put(`/enumerations/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.delete(`/enumerations/${id}`, { headers: authHeader() })
}

const findByType = (type) => {
  return http.get(`/enumerations/type/${type}`, { headers: authHeader() })
}

/**
 * Restituisce la descrizione associata all'ID nel tipo enumerativo
 * @param {[{id: number, description: string, type: string, order: number}]} enumeration Array di record della tabella enumerations
 * @param {number} id ID del valore enumerativo richiesto
 * @returns Descrizione testuale del valore enumerativo
 */
const getEnumerationDescription = (enumeration, id) => {
  const description =
    enumeration &&
    enumeration.filter((record) => record.id === id)[0] &&
    enumeration.filter((record) => record.id === id)[0].description
  return description
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByType,
  getEnumerationDescription
}
