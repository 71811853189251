import http from './http-common'
import authHeader from './auth-header'

const getAll = (folderId) => {
  if (folderId) {
    return http.get(`/folders/${folderId}/documents`, {
      headers: authHeader()
    })
  }
  return http.get('/documents', {
    headers: authHeader()
  })
}

const get = (folderId, id) => {
  return http.get(`/folders/${folderId}/documents/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.post(`/folders/${data.folderId}/documents/`, data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.put(`/folders/${data.folderId}/documents/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (folderId, id) => {
  return http.delete(`/folders/${folderId}/documents/${id}`, {
    headers: authHeader()
  })
}

export default {
  getAll,
  get,
  create,
  update,
  remove
}
