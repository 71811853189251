import http from './http-common'
import authHeader from './auth-header'

const uploadOne = (data) => {
  return http.post('/uploadOne', data, { headers: authHeader() })
}

const uploadMany = (data) => {
  return http.post('/uploadMany', data, { headers: authHeader() })
}

const downloadFile = (file) => {
  return http.get(`/download/${file}`, { headers: authHeader() })
}

export default {
  uploadOne,
  uploadMany,
  downloadFile
}
