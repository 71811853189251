const prod = {
  SERVER_URL: 'https://psrveneto.fgbsrl.it/api',
  SAMPLE_DATA: false,
  APP_NAME: 'PsrVeneto Manager'
}

const dev = {
  SERVER_URL: 'http://localhost:8080/api',
  SAMPLE_DATA: false,
  APP_NAME: 'PsrVeneto Manager dev.'
}

export const config = process.env.NODE_ENV === 'production' ? prod : dev
