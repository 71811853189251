import axios from 'axios'
import { config } from 'variables/Config.js'
import AuthService from './auth.service'

const axiosClient = axios.create()

axiosClient.defaults.baseURL = config.SERVER_URL

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

// All request will wait 2 seconds before timeout
axiosClient.defaults.timeout = 0

axiosClient.interceptors.response.use(
  function (response) {
    // Dispatch any action on success
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      // Add Logic to
      // 1. Redirect to login page or
      // 2. Request refresh token
      AuthService.logout()
    }
    return Promise.reject(error)
  }
)

export default axiosClient
