'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
// import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
// import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'
// import FormHelperText from '@mui/material/FormHelperText'
// import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import authService from 'services/auth.service'

import folderService from 'services/folder.service'
import mainService from 'services/document.service'
import uploadService from 'services/upload.service'
import { config } from 'variables/Config.js'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'

export default function ImageForm () {
  const { idObj } = useParams()
  const { idFolder } = useParams()
  const [obj, setObj] = React.useState({})
  const [folders, setFolders] = React.useState([])
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [showSnackbar, setShowSnackbar] = React.useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, control, getValues, reset } = useForm(
    {
      defaultValue: {
        albumId: idFolder
      }
    }
  )
  const navigate = useNavigate()
  const loggedUser = authService.user()

  useEffect(() => {
    fetchObject()
    fetchFolders()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({ folderId: '' + idFolder })
    } else {
      mainService.get(idFolder, idObj).then(
        (response) => {
          setObj(response.data)
          setFile(response.data.file)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const fetchFolders = () => {
    folderService.getAll().then(
      (response) => {
        setFolders(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const onSubmit = (data) => {
    data.file = file
    if (data.url === '' && data.file === '') {
      setShowSnackbar({ sh: true, msg: 'inserire un url o un file', color: 'error' })
      return
    }
    console.log('data: ', data)
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/folders/${data.folderId}/documents`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/folders/${data.folderId}/documents`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const uploadFile = (files) => {
    const newFile = files[0]
    if (newFile) {
      if (newFile.size <= 50 * 1024000) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', newFile)
        uploadService.uploadOne(formData).then(
          (response) => {
            setLoading(false)
            const filename = response.data.filename
            setFile(filename)
          },
          (error) => {
            setLoading(false)
            setShowSnackbar({ sh: true, msg: 'errore upload file', color: 'error' })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setFile('')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <MDBox display='flex'>
                  <MDBox
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='4rem'
                    height='4rem'
                    variant='gradient'
                    bgColor='info'
                    color='white'
                    shadow='md'
                    borderRadius='xl'
                    ml={3}
                    mt={-2}
                  >
                    <Icon fontSize='medium' color='inherit'>
                      insert_drive_file
                    </Icon>
                  </MDBox>
                  <MDTypography variant='h5' sx={{ mt: 2, mb: 1, ml: 2 }}>
                    Documento
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='nome'
                        error={!!(errors.name)}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.folderId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.folderId}
                        >
                          <InputLabel id='lblFolder'>Raccolta</InputLabel>
                          <Select
                            labelId='lblFolder'
                            sx={{ p: 1.5 }}
                            defaultValue={obj.folderId}
                            {...register('folderId', { required: true })}
                          >
                            <MenuItem key='0' value=''>seleziona una raccolta</MenuItem>
                            {folders && folders.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='descrizione'
                        error={!!(errors.description)}
                        {...register('description', { required: false })}
                      />
                    </Grid> */}

                    <Grid item xs={6} sm={6} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        label='url'
                        error={!!(errors.url)}
                        {...register('url', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} />
                    <Grid item xs={6} sm={6} md={6}>
                      <Stack spacing={2}>

                        {(file === null || file === undefined || file === '') && (
                          <label htmlFor='contained-button-file'>
                            <MDInput
                              accept='*'
                              id='contained-button-file'
                              type='file'
                              sx={{ display: 'none' }}
                              onChange={(target) => uploadFile(target.target.files)}
                            />
                            <MDButton
                              variant='gradient'
                              color='info'
                              component='span'
                              style={{ marginTop: '30px' }}
                            >
                              Upload documento (max 50Mb)
                            </MDButton>
                          </label>
                        )}
                        {loading && <CircularProgress color='primary' sx={{ m: 2 }} />}
                        {file && (
                          <MDButton
                            color='primary'
                            href={`${config.SERVER_URL}/download/${file}`}
                            target='_blank'
                            mb={2}
                          >
                            download
                          </MDButton>
                        )}
                        {file && authService.isAdmin() && (
                          <MDButton color='error' onClick={() => removeFile()}>
                            elimina file
                          </MDButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to={`/folders/${idFolder}/documents`} sx={{ mr: 1 }}
                  >
                    vai alla raccolta
                  </MDButton>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/dashboard/main' sx={{ mr: 1 }}
                  >
                    vai alla dashboard
                  </MDButton>
                  {authService.isAdmin() &&
                    <MDButton type='submit' variant='gradient' color='success'>
                      Salva
                    </MDButton>}
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Documento</MDTypography>

                <MDTypography variant='h4'>{obj.name}</MDTypography>
                <Stack spacing={2}>
                  {idObj !== 'new' &&
                    <MDButton
                      color='primary'
                      size='small'
                      href={`${config.SERVER_URL}/download/${obj.file}`}
                      target='_blank'
                      mb={2}
                    >
                      download
                    </MDButton>}
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
