import http from './http-common'
import authHeader from './auth-header'
import utils from 'utils/utils.js'

const getAll = ({ attributes, include } = {}) => {
  const queryStringSlices = []
  if (attributes) {
    queryStringSlices.push(attributes.map(attribute => `attributes=${attribute}`)
      .join('&'))
  }
  if (include) {
    queryStringSlices.push(include.map(child => `include=${child}`)
      .join('&'))
  }

  console.log('queryStringSlices', queryStringSlices)
  const queryString = !utils.isEmpty(queryStringSlices)
    ? '?' + queryStringSlices.join('&')
    : ''
  return http.get('/users' + queryString, { headers: authHeader() })
}

const get = (id) => {
  return http.get(`/users/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.post('/users', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.put(`/users/${id}`, data, { headers: authHeader() })
}

const updateProfile = (id, data) => {
  return http.put(`/users/profile/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.delete(`/users/${id}`, { headers: authHeader() })
}

const findByUsername = (username) => {
  return http.get(`/users?username=${username}`)
}

const findByEmail = (email) => {
  return http.get(`/users?email=${email}`)
}

export default {
  getAll,
  get,
  create,
  update,
  updateProfile,
  remove,
  findByUsername,
  findByEmail
}
