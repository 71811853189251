import React, { useEffect, useMemo } from 'react'

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import Pagination from '@mui/material/Pagination'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'

// zerouno
import authService from 'services/auth.service'
import mainService from 'services/image.service'
import albumService from 'services/album.service'
import { config } from 'variables/Config.js'
import { useParams, Link } from 'react-router-dom'

function MainDashboard () {
  const [objId, setObjId] = React.useState(0)
  const { idAlbum } = useParams()
  const [obj, setObj] = React.useState([])
  const [albums, setAlbums] = React.useState([])
  const [objects, setObjects] = React.useState([])
  const [filtered, setFiltered] = React.useState([])
  const [showSnackbar, setShowSnackbar] = React.useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))
  const loggedUser = authService.user()

  useEffect(() => {
    fetchObjects()
    fetchAlbums()
  }, [])

  const fetchObjects = () => {
    mainService.getAll().then(
      (response) => {
        setObjects(response.data)
        setFiltered(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  const fetchAlbums = () => {
    albumService.getAll().then(
      (response) => {
        setAlbums(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(idAlbum, objId).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  function handleSearch (event) {
    const v = event.target.value
    const qArray = v.split(' ')
    const result = objects.filter(item => {
      const allW = qArray.every((w) => {
        return (item.title.includes(w) || item.description.includes(w) || item.tags.includes(w))
      })
      return allW
    })
    setFiltered(result)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox display='flex'>
            <MDBox
              display='flex'
              justifyContent='center'
              alignItems='center'
              width='4rem'
              height='4rem'
              variant='gradient'
              bgColor='info'
              color='white'
              shadow='md'
              borderRadius='xl'
              ml={3}
              mt={-2}
            >
              <Icon fontSize='medium' color='inherit'>
                photo
              </Icon>
            </MDBox>
            <MDTypography variant='h5' sx={{ mt: 2, mb: 1, ml: 2 }}>
              Repository Immagini PSR Veneto
            </MDTypography>
          </MDBox>

          <Grid container spacing={2} p={2}>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <MDInput label='Cerca Foto' defaultValue='' onChange={handleSearch} sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} md={12}>
              {albums.map(item =>
                <MDButton
                  key={item.id}
                  color='info'
                  variant='outlined'
                  component={Link}
                  to={`/albums/${item.id}/images`}
                  sx={{ m: 1 }}
                >
                  {item.name}
                </MDButton>
              )}
            </Grid>
          </Grid>

          <ImageList variant='masonry' cols={matches ? 4 : 1} gap={4} sx={{ p: 3 }}>
            {filtered.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  src={config.SERVER_URL + '/download/min_' + item.file}
                  srcSet={`${config.SERVER_URL}/download/min_${item.file}`}
                  alt={item.title}
                  loading='lazy'
                />
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.tags}
                  actionIcon={
                    <Grid container spacing={1} sx={{ mr: 1 }}>
                      <Grid item>
                        <MDButton
                          color='primary'
                          size='small'
                          iconOnly
                          href={`${config.SERVER_URL}/download/${item.file}`}
                          target='_blank'
                        >
                          <Icon>download</Icon>
                        </MDButton>

                      </Grid>
                      <Grid item>
                        <MDButton
                          color='info'
                          size='small'
                          iconOnly
                          component={Link}
                          to={`/albums/${item.albumId}/images/${item.id}`}
                        >
                          <Icon>edit</Icon>
                        </MDButton>

                      </Grid>
                      {authService.isAdmin() &&
                        <Grid item>
                          <MDButton
                            iconOnly
                            color='error'
                            size='small'
                            onClick={() => {
                              setObjId(item.id)
                              setOpenConfirm(true)
                            }}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </Grid>}
                    </Grid>

                  }
                />
              </ImageListItem>
            ))}

          </ImageList>
          {/* <Pagination count={objects.length / 3} color='info' page={page} onChange={handleChangePagination} /> */}

        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}

export default MainDashboard
