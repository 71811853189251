'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getAll = () => {
  return http.get('/albums', { headers: authHeader() })
}

const get = (id) => {
  return http.get(`/albums/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.post('/albums', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.put(`/albums/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.delete(`/albums/${id}`, { headers: authHeader() })
}

const findByName = (name) => {
  return http.get(`/albums?name=${name}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByName
}
