import React, { useEffect } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerounorabbit
import authService from 'services/auth.service'
import mainService from 'services/album.service'
import { Link } from 'react-router-dom'

export default function AlbumTable () {
  const [objId, setObjId] = React.useState(0)
  const [objects, setObjects] = React.useState([])
  const [showSnackbar, setShowSnackbar] = React.useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const loggedUser = authService.user()

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    mainService.getAll().then(
      (response) => {
        setObjects(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // Delete
  const deleteObject = (id) => {
    setOpenConfirm(false)
    mainService.remove(id).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const columns = [
    {
      Header: 'nome',
      accessor: 'name'
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: 'id',
      Cell: ({ value, row }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              component={Link}
              to={'/albums/' + value + '/images'}
            >
              immagini
            </MDButton>
          </Grid>
          {authService.isAdmin() &&
            <Grid item>
              <MDButton
                color='info'
                size='small'
                iconOnly
                component={Link}
                to={'/albums/' + value}
              >
                <Icon>edit</Icon>
              </MDButton>
            </Grid>}
          {authService.isAdmin() &&
            <Grid item>
              <MDButton
                iconOnly
                color='error'
                size='small'
                onClick={() => {
                  setObjId(value)
                  setOpenConfirm(true)
                }}
              >
                <Icon>delete</Icon>
              </MDButton>
            </Grid>}
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox display='flex'>
            <MDBox
              display='flex'
              justifyContent='center'
              alignItems='center'
              width='4rem'
              height='4rem'
              variant='gradient'
              bgColor='info'
              color='white'
              shadow='md'
              borderRadius='xl'
              ml={3}
              mt={-2}
            >
              <Icon fontSize='medium' color='inherit'>photo_album</Icon>
            </MDBox>
            <MDTypography variant='h5' sx={{ mt: 2, mb: 1, ml: 2 }}>
              Albums
            </MDTypography>
          </MDBox>

          <MDBox p={3} lineHeight={1}>
            {authService.isAdmin() &&
              <MDButton color='info' component={Link} to='/albums/new' m={1}>
                aggiungi
              </MDButton>}
          </MDBox>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
