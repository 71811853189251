import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerounorabbit conf
import mainService from 'services/folder.service'
import uploadService from 'services/upload.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'

export default function FolderForm () {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const navigate = useNavigate()

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({})
    } else {
      mainService.get(idObj).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const onSubmit = (data) => {
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/folders')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/folders')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // const uploadFile = (files) => {
  //   const file = files[0]
  //   if (file) {
  //     if (file.size <= 1024000) {
  //       const formData = new FormData()
  //       formData.append('file', file)
  //       uploadService.uploadOne(formData).then(
  //         (response) => {
  //           const image = response.data.filename
  //           setObj({ ...obj, image: image })
  //         },
  //         (error) => {
  //           setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
  //         }
  //       )
  //     } else {
  //       setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
  //     }
  //   }
  // }

  // const removeFile = () => {
  //   const formData = new FormData()
  //   formData.delete('file')
  //   setObj({ ...obj, image: '' })
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <MDBox display='flex'>
                  <MDBox
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='4rem'
                    height='4rem'
                    variant='gradient'
                    bgColor='info'
                    color='white'
                    shadow='md'
                    borderRadius='xl'
                    ml={3}
                    mt={-2}
                  >
                    <Icon fontSize='medium' color='inherit'>folder</Icon>
                  </MDBox>
                  <MDTypography variant='h5' sx={{ mt: 2, mb: 1, ml: 2 }}>
                    Raccolta
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        autoFocus
                        label='nome'
                        error={!!(errors.name)}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/folders/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Raccolta</MDTypography>
                <MDTypography variant='h4'>{obj.name}</MDTypography>
                {idObj !== 'new' &&

                  <Stack spacing={2}>
                    <MDButton
                      color='info'
                      component={Link}
                      to={'/folders/' + idObj + '/documents'}
                    >
                      documenti
                    </MDButton>
                  </Stack>}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
