'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getAll = () => {
  return http.get('/folders', { headers: authHeader() })
}

const get = (id) => {
  return http.get(`/folders/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.post('/folders', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.put(`/folders/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.delete(`/folders/${id}`, { headers: authHeader() })
}

const findByName = (name) => {
  return http.get(`/folders?name=${name}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByName
}
