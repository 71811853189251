import moment from 'moment'
import 'moment/locale/it' // Per gestire date

function isEmpty (value) {
  return value === null || value === '' || value === undefined || value.length === 0
}

function formatDate (value, pattern) {
  // TODO: Value tipo data, pattern tipo stringa "DD/MM/YYYY HH:mm"
  return moment(value).format(pattern)
}

export default {
  isEmpty,
  formatDate
}
