/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import ProfileOverview from 'layouts/pages/profile/profile-overview'
import Settings from 'layouts/pages/account/settings'

// Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar'

// @mui icons
import Icon from '@mui/material/Icon'

// Images
import profilePicture from 'assets/images/avatar.png'
// ZeroUno
import { config } from 'variables/Config.js'
import authService from 'services/auth.service'
import LoginPage from 'views/auth/login.js'
import UserProfile01 from 'views/users/UserProfile.js'
import UsersTable from 'views/users/UsersTable.js'
import UserForm from 'views/users/UserForm.js'
import EnumerationTable from 'views/enumerations/EnumerationTable.js'
import EnumerationForm from 'views/enumerations/EnumerationForm.js'
import AlbumTable from 'views/crm/AlbumTable.js'
import AlbumForm from 'views/crm/AlbumForm.js'
import ImageTable from 'views/crm/ImageTable.js'
import ImageForm from 'views/crm/ImageForm.js'
import FolderTable from 'views/crm/FolderTable.js'
import FolderForm from 'views/crm/FolderForm.js'
import DocumentTable from 'views/crm/DocumentTable.js'
import DocumentForm from 'views/crm/DocumentForm.js'
import MainDashboard from 'layouts/dashboards/main'

const loggedUser = authService.user()

const routes = [
  {
    type: 'collapse',
    name: loggedUser ? loggedUser.name + ' ' + loggedUser.surname : '',
    key: 'loggedUser',
    icon: <MDAvatar src={loggedUser && loggedUser.image ? config.SERVER_URL + '/download/' + loggedUser.image : profilePicture} alt='imgProfile' size='sm' />,
    collapse: [
      {
        name: 'Il Mio Profilo',
        key: 'il-mio-profilo',
        component: <UserProfile01 />,
        route: '/myprofile',
        invisible: false
      },
      {
        name: 'My Profile',
        key: 'my-profile',
        route: '/pages/profile/profile-overview',
        component: <ProfileOverview />,
        invisible: !config.SAMPLE_DATA
      },
      {
        name: 'Settings',
        key: 'profile-settings',
        route: '/pages/account/settings',
        component: <Settings />,
        invisible: !config.SAMPLE_DATA
      },
      {
        name: 'Logout',
        key: 'logout',
        route: '/login',
        component: <LoginPage />,
        invisible: false
      }
    ]
  },
  { type: 'divider', key: 'divider-0' },
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'dashboards',
    icon: <Icon fontSize='medium'>dashboard</Icon>,
    invisible: authService.isOperator(),
    collapse: [
      {
        name: 'Dashboard',
        key: 'dashboard',
        icon: <Icon fontSize='medium'>dashboard</Icon>,
        route: '/dashboards/main',
        component: <MainDashboard />,
        invisible: authService.isOperator()
      }
    ]
  },
  {
    type: 'collapse',
    name: 'PSR Veneto',
    key: 'crm',
    icon: <Icon fontSize='medium'>apps</Icon>,
    collapse: [
      {
        name: 'Utente',
        key: 'user',
        route: '/users/:idUser',
        component: <UserForm />,
        invisible: true
      },
      {
        name: 'Utenti',
        key: 'users',
        route: '/users',
        component: <UsersTable />,
        invisible: !authService.isAdmin()
      },
      {
        name: 'Album',
        key: 'album',
        route: '/albums/:idObj',
        component: <AlbumForm />,
        invisible: true
      },
      {
        name: 'Album',
        key: 'albums',
        route: '/albums',
        component: <AlbumTable />,
        invisible: !(authService.isAdmin() || authService.isUser())
      },
      {
        name: 'Immagine',
        key: 'image',
        route: '/albums/:idAlbum/images/:idObj',
        component: <ImageForm />,
        invisible: true
      },
      {
        name: 'Immagini',
        key: 'images',
        route: '/albums/:idAlbum/images',
        component: <ImageTable />,
        invisible: true
      },
      {
        name: 'Raccolta',
        key: 'raccolta',
        route: '/folders/:idObj',
        component: <FolderForm />,
        invisible: true
      },
      {
        name: 'Raccolte',
        key: 'raccolte',
        route: '/folders',
        component: <FolderTable />,
        invisible: !(authService.isAdmin() || authService.isUser() || authService.isOperator())
      },
      {
        name: 'Documento',
        key: 'documento',
        route: '/folders/:idFolder/documents/:idObj',
        component: <DocumentForm />,
        invisible: true
      },
      {
        name: 'Documenti',
        key: 'documenti',
        route: '/folders/:idFolder/documents',
        component: <DocumentTable />,
        invisible: true
      },
      {
        name: 'Descrizioni',
        key: 'enumerations',
        route: '/enumerations',
        component: <EnumerationTable />,
        invisible: true
      },
      {
        name: 'Descrizione',
        key: 'enumeration',
        route: '/enumerations/:idObj',
        component: <EnumerationForm />,
        invisible: true
      }
    ]
  },
  { type: 'divider', key: 'divider-1' },
  {
    type: 'collapse',
    name: 'Fondazione Brodolini',
    key: 'brodolini',
    href: 'https://www.fondazionebrodolini.it',
    icon: <Icon fontSize='medium'>engineering</Icon>,
    noCollapse: true,
    invisible: true
  }
]

export default routes
