import http from './http-common'
import authHeader from './auth-header'

const getAll = (albumId) => {
  if (albumId) {
    return http.get(`/albums/${albumId}/images`, {
      headers: authHeader()
    })
  }
  return http.get('/images', {
    headers: authHeader()
  })
}

const get = (albumId, id) => {
  return http.get(`/albums/${albumId}/images/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.post(`/albums/${data.albumId}/images/`, data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.put(`/albums/${data.albumId}/images/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (albumId, id) => {
  return http.delete(`/albums/${albumId}/images/${id}`, {
    headers: authHeader()
  })
}

export default {
  getAll,
  get,
  create,
  update,
  remove
}
